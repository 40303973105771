import React, { useState } from "react";
import PointsModel from "../../../../../models/features/loyalty/PointsModel/PointsModel";
import PointsComponent from "./PointsComponent";

const votingTabs = ["poll", "potm"];

type LoyaltyPointsProps = {
  openTab: string;
  loyaltyPoints: PointsModel;
  onChange: (
    scenario: "Points",
    uid: string,
    value: string,
    scenarioNode: string,
    openTab?: string,
    correctScoreAdvancedAlternativeKey?: string
  ) => void;
};

const LoyaltyPoints: React.FC<LoyaltyPointsProps> = ({
  openTab,
  loyaltyPoints,
  onChange,
}) => {
  const onChangePoints = (
    uid: string,
    event: React.ChangeEvent<HTMLInputElement>,
    correctScoreAdvancedAlternativeKey?: string
  ) => {
    onChange(
      "Points",
      uid,
      event.target.value,
      openTab,
      correctScoreAdvancedAlternativeKey
    );
  };

  return (
    <>
      <div
        className={openTab === "general" ? "block" : "hidden"}
        id="Edit loyalty general points features"
      >
        <PointsComponent
          generalPoints={loyaltyPoints.general}
          onChange={onChangePoints}
          type={openTab}
        />
      </div>
      <div
        className={openTab === "predictor" ? "block" : "hidden"}
        id="Edit loyalty predictor points features"
      >
        <PointsComponent
          generalPoints={loyaltyPoints.predictor}
          onChange={onChangePoints}
          type={openTab}
        />
      </div>
      <div
        className={openTab === "activity" ? "block" : "hidden"}
        id="Edit loyalty activity points features"
      >
        <PointsComponent
          generalPoints={loyaltyPoints.activity}
          onChange={onChangePoints}
          type={openTab}
        />
      </div>
      <div
        className={openTab === "top x" ? "block" : "hidden"}
        id="Edit loyalty top x points features"
      >
        <PointsComponent
          generalPoints={loyaltyPoints.topX}
          onChange={onChangePoints}
          type={openTab}
        />
      </div>
      <div
        className={openTab === "match quiz" ? "block" : "hidden"}
        id="Edit loyalty match quiz points features"
      >
        <PointsComponent
          generalPoints={loyaltyPoints.matchQuiz}
          onChange={onChangePoints}
          type={openTab}
        />
      </div>
      <div
        className={openTab === "discussion post" ? "block" : "hidden"}
        id="Edit discussion post points features"
      >
        <PointsComponent
          generalPoints={loyaltyPoints.discussionPost}
          onChange={onChangePoints}
          type={openTab}
        />
      </div>
      <div
        className={openTab === "voting" ? "block" : "hidden"}
        id="Edit voting points features"
      >
        <div className="flex items-center">
          <PointsComponent
            generalPoints={loyaltyPoints.voting.poll}
            onChange={(uid, event) => onChangePoints(uid, event, "poll")}
            type={openTab}
          />
          <PointsComponent
            generalPoints={loyaltyPoints.voting.potm}
            onChange={(uid, event) => onChangePoints(uid, event, "potm")}
            type={openTab}
          />
        </div>
      </div>
    </>
  );
};

export default LoyaltyPoints;
