import React, { useContext, useEffect, useState } from "react";
import FollowedEntitiesBuilder from "../../../../builders/dashboard/FollowedEntitiesBuilder";
import {
  ApiContext,
  ApiContextValueType,
} from "../../../../providers/ApiProvider";
import TopFollowedEntity from "./TopFollowedEntity";
import FollowedEntitiesModel from "../../../../models/Reports/FollowedEntitiesModel";
import { GlobalStatesContext } from "../../../../providers/GlobalStatesProvider";

const FollowedEntities: React.FC = () => {
  const [followedEntities, setFollowedEntities] =
    useState<FollowedEntitiesModel>(new FollowedEntitiesModel());

  const api = useContext(ApiContext);
  const { selectedClient } = useContext(GlobalStatesContext);

  const builder = new FollowedEntitiesBuilder(api as ApiContextValueType);
  const topFollowedEntitiesKeys = Object.keys(new FollowedEntitiesModel());

  useEffect(() => {
    builder
      .build()
      .then((followedEntities: FollowedEntitiesModel) =>
        setFollowedEntities(followedEntities)
      )
      .catch((e) => {
        setFollowedEntities(new FollowedEntitiesModel());
        console.error(e);
      });
  }, [selectedClient]);

  return (
    <div className="flex flex-wrap">
      {topFollowedEntitiesKeys.map((value: string) => (
        <div
          key={`${value}-${Math.random()}`}
          className="xl:w-6/12 mb-12 h-auto px-4 w-full"
        >
          <TopFollowedEntity
            entity={value}
            topFollowedEntity={
              followedEntities[value as keyof typeof followedEntities]
            }
          />
        </div>
      ))}
    </div>
  );
};

export default FollowedEntities;
