import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ERROR_MESSAGES } from "../../../../enums/enums";
import ProfileInfo from "../../../../models/Clients/ProfileInfo";
import EditButton from "../../../Buttons/CreateStaffButton";
import TableHeader from "../../Dashboard/FollowedEntities/TableHeader";
import StaffMember from "./StaffMember";
import StaffMembersHeaderCells from "./StaffMembersHeaderCells";
import dayjs from "dayjs";
import { useHistory } from "react-router";
import Spinner from "../../../Spinner/Spinner";
import { GlobalStatesContext } from "../../../../providers/GlobalStatesProvider";
import { ApiContext } from "../../../../providers/ApiProvider";

const StaffMembers: React.FC = () => {
  const [staffMembers, setStaffMembers] = useState<ProfileInfo[]>(null);

  const { clientHttps } = useContext(ApiContext);
  const { selectedClient, isModalConfirmActionSubmitted } =
    useContext(GlobalStatesContext);

  const history = useHistory();

  useEffect(() => {
    const disableCache = dayjs().unix();

    clientHttps
      .getStaffMembersForClient(disableCache)
      .then((response: ProfileInfo[]) => {
        setStaffMembers(response);
      })
      .catch((error) => {
        console.error(error);
        toast.error(ERROR_MESSAGES.STAFF_MEMBERS);
        setStaffMembers([]);
      });

    return () => setStaffMembers(null);
  }, [selectedClient, isModalConfirmActionSubmitted.current]);

  const createStaffMember = () => history.push(`/staff/create`);

  if (staffMembers && staffMembers.length === 0) {
    return (
      <div className="relative flex flex-col min-w-0 break-words w-full shadow-lg rounded bg-white">
        <div className="flex flex-row items-center justify-between px-4">
          <TableHeader title={"Staff members"} />
          <div className="mr-3">
            <EditButton label={"Create"} onClick={createStaffMember} />
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          <table className="items-center w-full bg-transparent border-collapse">
            <StaffMembersHeaderCells />
            <tbody>
              <tr>
                <td colSpan={5}>
                  <h3 className="text-center text-blueGray-600 py-20 font-semibold">
                    No staff members available. To create one click on 'Create'.
                  </h3>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  return (
    <div className="relative flex flex-col min-w-0 break-words w-full shadow-lg rounded bg-white">
      <div className="flex flex-row items-center justify-between px-4">
        <TableHeader title={"Staff members"} />
        <div className="mr-3">
          <EditButton label={"Create"} onClick={createStaffMember} />
        </div>
      </div>
      <div className="block w-full overflow-x-auto">
        <table className="items-center w-full bg-transparent border-collapse">
          <StaffMembersHeaderCells />
          <tbody>
            {!staffMembers ? (
              <tr>
                <td colSpan={5}>
                  <Spinner />
                </td>
              </tr>
            ) : (
              <></>
            )}
            {staffMembers && staffMembers.length ? (
              staffMembers.map((member: ProfileInfo) => (
                <StaffMember key={member.id} staffMember={member} />
              ))
            ) : (
              <></>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default StaffMembers;
