import SDKConfigurationModel from "fansunited-sdk-esm/Core/Configurator/Models/SDKConfiguraitonModel";
import { InfrastructureType } from "../../models/Clients/ClientsBasicInfo";
import { initApiKey, initEnvironment } from "../../helpers/helpers";

export const initSDKConfig = (
  clientId: string,
  infrastructure: InfrastructureType
): SDKConfigurationModel => {
  const apiKey = initApiKey(infrastructure || "PROD");
  const environment = initEnvironment(infrastructure);

  const config: SDKConfigurationModel = {
    apiKey,
    clientId,
    lang: "en",
    environment,
    idSchema: "native",
    errorHandlingMode: "standard",
    authProvider: {
      getIdToken: () => {
        return "";
      },
      logout: () => {},
    },
  };

  return config;
};
