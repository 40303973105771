import axios, { AxiosInstance } from "axios";
import { getToken } from "../../sdks/firebase/getToken";
import { InfrastructureType } from "../../models/Clients/ClientsBasicInfo";
import { initApiKey } from "../../helpers/helpers";

export default class Https {
  private apiKey = null;
  protected axiosInstance: AxiosInstance;

  constructor(infrastructure: InfrastructureType) {
    this.apiKey = initApiKey(infrastructure || "PROD");
    this.axiosInstance = axios.create({
      headers: {
        "Content-Type": "application/json",
      },
    });

    this.axiosInstance.interceptors.request.use(
      async (config) => {
        config.params = {
          key: this.apiKey,
          ...config.params,
        };
        const token = await getToken();
        if (token) {
          config.headers!.Authorization = "Bearer " + token;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }
}
