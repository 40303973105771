import React, { useCallback, useContext } from "react";
import { ConfigurationManager } from "fansunited-widget-client-configuration";
import { GlobalStatesContext } from "../../../providers/GlobalStatesProvider";
import { initApiKey, initEnvironment } from "../../../helpers/helpers";
import CardWidget from "../../Cards/CardWidget";

const Configuration: React.FC = () => {
  const { selectedClient } = useContext(GlobalStatesContext);

  const fansUnitedApiKey = initApiKey(selectedClient?.infrastructure || "PROD");
  const fansUnitedClientId = selectedClient.id;
  const fansUnitedEnvironment = initEnvironment(selectedClient.infrastructure);
  const fansUnitedLanguage = "en";

  const displayWidget = useCallback(() => {
    return (
      <ConfigurationManager
        key={`${fansUnitedClientId}-${fansUnitedEnvironment}`}
        fansUnitedApiKey={fansUnitedApiKey}
        fansUnitedClientId={fansUnitedClientId}
        fansUnitedEnvironment={fansUnitedEnvironment}
        fansUnitedLanguage={fansUnitedLanguage}
        hideSignOutButton={true}
      />
    );
  }, [fansUnitedClientId, fansUnitedEnvironment]);

  if (!selectedClient && !selectedClient.id) return null;

  return <CardWidget>{displayWidget()}</CardWidget>;
};

export default Configuration;
